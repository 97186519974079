(function() {

var SvgChartMapping = MST.SvgChartMapping;
var SvgChartDrawUtil = MST.SvgChartDrawUtil;
var SvgChartUtil = MST.SvgChartUtil;
var SvgChartTechnical = MST.SvgChartTechnical;

var SvgChartActionView = function (chart, viewDiv) {
	this.chart = chart;
	this.mapping = new SvgChartMapping();
	this.mapping.panel = chart.chartConfig.actionView.panel;

	this.actionDiv = document.createElement("div");
	viewDiv.appendChild(this.actionDiv);

	this.actionDiv.style.position = "absolute";
	this.actionDiv.style.width = "100%";
	this.actionDiv.style.height = "100%";
	this.actionDiv.style.zIndex = 5;
	this.actionDiv.className = "SvgChartActionView";
	
	if (this.chart.chartConfig.type != 'PC') {
		this.actionDiv.style.zIndex = 8;
	}

	this.actionCanvasDiv = document.createElement("div");
	viewDiv.appendChild(this.actionCanvasDiv);

	this.actionCanvasDiv.style.position = "absolute";
	this.actionCanvasDiv.style.width = "100%";
	this.actionCanvasDiv.style.height = "100%";
	this.actionCanvasDiv.style.zIndex = 4;

	this.actionCanvasDiv1 = document.createElement("div");
	viewDiv.appendChild(this.actionCanvasDiv1);

	this.actionCanvasDiv1.style.position = "absolute";
	this.actionCanvasDiv1.style.width = "100%";
	this.actionCanvasDiv1.style.height = "100%";
	this.actionCanvasDiv1.style.zIndex = 4;

	this.dataDiv = document.createElement("div");
	viewDiv.appendChild(this.dataDiv);
	this.dataDiv.className = "SvgChartViewData";
	this.dataDiv.style.position = "absolute";
	this.dataDiv.style.zIndex = 100;
	this.dataDiv.style.visibility = "hidden";

	this.xAxisDiv = document.createElement("div");
	viewDiv.appendChild(this.xAxisDiv);
	this.xAxisDiv.className = "xAxisDiv";
	this.xAxisDiv.style.position = "absolute";
	this.xAxisDiv.style.zIndex = 4;
	this.xAxisDiv.style.visibility = "hidden";

	this.yAxisDiv = document.createElement("div");
	viewDiv.appendChild(this.yAxisDiv);
	this.yAxisDiv.className = "yAxisDiv";
	this.yAxisDiv.style.position = "absolute";
	this.yAxisDiv.style.zIndex = 4;
	this.yAxisDiv.style.visibility = "hidden";

	this.actionCanvas = SvgChartDrawUtil.createCanvas(this.actionCanvasDiv, this.chart.chartWidth, 300);
	this.actionCanvas1 = SvgChartDrawUtil.createCanvas(this.actionCanvasDiv1, this.chart.chartWidth, 300);

	this.chart.newsCanvas = SvgChartDrawUtil.createCanvas(this.actionDiv, this.chart.chartWidth, 300);

	if (this.chart.chartConfig.actionView.active) {
		var util = new SvgChartUtil();
		util.attachTouchEvent(this, this.actionDiv);
	}
};

SvgChartActionView.prototype.chart = null;
SvgChartActionView.prototype.mapping = null;
SvgChartActionView.prototype.actionDiv = null;
SvgChartActionView.prototype.actionCanvasDiv = null;
SvgChartActionView.prototype.actionCanvas = null;
SvgChartActionView.prototype.actionCanvasDiv1 = null;
SvgChartActionView.prototype.actionCanvas1 = null;
SvgChartActionView.prototype.panelActionStatus = null;
SvgChartActionView.prototype.drawAreaHeight = null;
SvgChartActionView.prototype.index = null;
SvgChartActionView.prototype.dataDiv = null;
SvgChartActionView.prototype.dataList = null;


SvgChartActionView.prototype.onTouchEvent = function(target, pharse, cursorCoords)
{
	if(this.mapping.status != SvgChartTechnical.DATA_STATUS_OK) {
		return true;
	}

    if (cursorCoords != null) {
        if (pharse == 'touchstart') {
            var viewList = this.chart.viewList;
            var offsetY = 0;
            var y = cursorCoords.y;
            for (var i = 0; i < viewList.length; i++) {
                var y0 = viewList[i].mapping.paddingTop;
                var y1 = viewList[i].mapping.drawAreaHeight - viewList[i].mapping.paddingBottom;
                if (y >= y0 && y < y1 && i > 0) {
                    // cursorCoords.y = y;
                    // viewList[i].onTouchEvent(pharse, cursorCoords);
                    this.moveView = viewList[i];
                    this.moveViewTop = 0;
                    this.moveStatus = {y: cursorCoords.y};
                    this.moveView.frameDiv.style.opacity = 0.5;
                    break;
                }
                y -= viewList[i].mapping.drawAreaHeight;
            }

        } else if (pharse == 'touchmove' && this.moveView != null) {
            var y = cursorCoords.y;
            this.moveViewTop += y - this.moveStatus.y;
            this.moveView.frameDiv.style.top = this.moveViewTop + "px";
            this.moveStatus.y = y;
        }
    }

    if (this.moveView != null && pharse == 'touchend') {
        var viewIndex = null;
        var viewList = this.chart.viewList;
        var y = this.moveStatus.y;
        for (var i = 0; i < viewList.length; i++) {
            var y0 = viewList[i].mapping.paddingTop;
            var y1 = viewList[i].mapping.drawAreaHeight - viewList[i].mapping.paddingBottom;
            if (y >= y0 && y < y1) {
                viewIndex = i;
                break;
            }
            y -= viewList[i].mapping.drawAreaHeight;
        }
        if (viewIndex != null && this.moveView.index != viewIndex) {
            console.log("mergeView " + this.moveView.index + "," + viewIndex);
            this.chart.mergeView(this.moveView.index, viewIndex);
        } else {
            this.moveView.frameDiv.style.top = "0px";
            this.moveView.frameDiv.style.opacity = 1;
        }

        this.moveView = null;
    }

    if (this.moveView != null && pharse == "touchout") {
        this.moveView.frameDiv.style.top = "0px";
        this.moveView = null;
    }
	
	// if (cursorCoords != null) {
	// 	if (!this.chart.sketchMode && pharse == 'touchstart') {
	// 		this.chart.sketch = null;
	// 	}
	//
	// 	var viewList = this.chart.viewList;
	// 	var offsetY = 0;
	// 	var y = cursorCoords.y;
	// 	for (var i = 0; i < viewList.length; i++) {
	// 		var y0 = viewList[i].mapping.paddingTop;
	// 		var y1 = viewList[i].mapping.drawAreaHeight - viewList[i].mapping.paddingBottom;
	// 		if (y >= y0 && y < y1) {
	// 			cursorCoords.y = y;
	// 			viewList[i].onTouchEvent(pharse, cursorCoords);
	// 			break;
	// 		}
	// 		y -= viewList[i].mapping.drawAreaHeight;
	// 	}
    //
	// 	if (!this.chart.sketchMode && pharse == 'touchstart') {
	// 		if (this.chart.sketch != null) {
	// 			if (this.chart.sketch.selectedPoint != null) {
	// 				this.actionDiv.style.cursor = "ne-resize";
	// 			} else {
	// 				this.actionDiv.style.cursor = "move";
	// 			}
	// 			if (typeof(lineIconShow) != "undefined") lineIconShow(true);
	// 		} else {
	// 			this.actionDiv.style.cursor = "";
	// 			if (typeof(lineIconShow) != "undefined") lineIconShow(false);
	// 		}
	// 	}
	// }
	//
	// if (!this.chart.sketchMode && (pharse == 'touchend' || pharse == "touchout")) {
	// 	if (this.chart.sketch != null) {
	// 		this.chart.sketch.moveStatus = null;
	// 		this.chart.sketch.selectedPoint = null;
	// 		this.chart.sketch = null;
	// 		this.actionDiv.style.cursor = "";
	// 	}
	// }

	if (this.chart.sketch != null) {
		return this.onPanelTouchEvent("touchout", cursorCoords);
	}

	if (this.moveView != null) {
        return this.onPanelTouchEvent("touchout", cursorCoords);
    }
	
	return this.onPanelTouchEvent(pharse, cursorCoords);
};

SvgChartActionView.prototype.hitTestPanel = function(x, y)
{
//
	// var x0 = this.mapping.paddingLeft;
	// var x1 = this.mapping.drawAreaWidth - this.mapping.paddingRight;
	// var y0 = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
	// var y1 = this.mapping.drawAreaHeight - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING - SvgChartGlobal.LAYOUT_PANEL_HAXIS_HEIGHT + 0.5;
//
	// if(x > x0 && x < x1 && y > y0 && y < y1) {
		// return "inside";
	// }
		return "inside";
};

SvgChartActionView.prototype.onPanelTouchEvent = function(pharse, cursorCoords)
{
	var preventDefault = false;

	var x, y, ax, ay;

	if(cursorCoords != null) {
		x = cursorCoords.x;
		y = cursorCoords.y;
		ax = cursorCoords.ax;
		ay = cursorCoords.ay;
	}

	if(pharse == "touchstart") {
		var hittest = this.hitTestPanel(x, y);

		if(hittest != null) {
			// preventDefault = true;

			this.panelActionStatus = {x: x, y: y, ax:ax, ay:ay};
			// this.dataDiv.style.visibility = "visible";
			this.onClick();
		}
	}
	else if(pharse == "touchmove") {
		// if(this.panelActionStatus != null) {
			preventDefault = true;

			this.panelActionStatus = {x: x, y: y, ax:ax, ay:ay};
			this.drawPanelAction();
		// }
	}
	else if(pharse == "touchout") {
		if(this.panelActionStatus != null) {
			// preventDefault = true;

			this.panelActionStatus = null;
			this.drawPanelAction();
			if (this.chart.newsDiv == undefined) {
				this.dataDiv.style.visibility = "hidden";
			}
			this.xAxisDiv.style.visibility = "hidden";
			this.yAxisDiv.style.visibility = "hidden";
			this.index = -1;
		}
	}

	return !preventDefault;
	// return true;
};


SvgChartActionView.prototype.resetColor = function() {
	for (var i = 0; i < this.chart.viewList.length; i++) {
		for (var t = 0; t < this.chart.viewList[i].technicalList.length; t++) {
			var technical = this.chart.viewList[i].technicalList[t];
			var elements = technical.elements;
			if (elements != null) {
				var element = elements[this.index];
				if (element != null) {

					if (technical.dataColor != null && technical.dataColorSelect != null) {
						for (var d = 0; d < technical.dataColor.length; d++) {
							if (SvgChartDrawUtil.getAttr(element, "fill") == technical.dataColorSelect[d]) {
								SvgChartDrawUtil.setAttr(element, "fill", technical.dataColor[d]);
							}
						}
					}
				}
			}
		}
	}
};

SvgChartActionView.prototype.onClick = function()
{
	this.chart.onclick();
	var mapping = this.chart.viewList[0].mapping;
	if (this.panelActionStatus != null && this.chart.mainTechnical.data != null) {
		var index = Math.max(mapping.dataStartIndex, Math.min(mapping.dataStartIndex + mapping.dataLength - 1, Math.round(mapping.unTransformIndex(this.panelActionStatus.x))));
	}
	if (this.panelActionStatus != null && this.chart.mainTechnical.data != null) {

		var ax = this.panelActionStatus.ax;
		var ay = this.panelActionStatus.ay;

		var x = mapping.transformIndex(index) + 0.5;

		if (this.chart.mainTechnical.data[index] == null) {
			return;
		}
		var v = this.chart.mainTechnical.data[index]["close"];
		var start = Math.max(0, mapping.dataStartIndex);
		var startv = this.chart.mainTechnical.data[start]["close"];

		if (mapping.benchmark == true) {
			v = (v / startv - 1) * 100;
		}
		var y = mapping.transformValue(v, this.chart.mainTechnical.vAxis.vAxis) + 0.5;

		var data = this.chart.mainTechnical.data[index];

		var date;

		if(data != null) {
			date = this.chart.detailTimeFormat(data.date);
		}

//		if (this.chart.mainTechnical.showPick() && this.chart.pickTechnical.data != null) {
//			if (Math.abs(ax - x) < SvgChartGlobal.PICK_WIDTH && Math.abs(ay - y) < SvgChartGlobal.PICK_WIDTH) {
//				var pickData =  this.chart.pickTechnical.data;
//				if (pickData[index] != null && pickData[index].path != null) {
//					window.location.href = pickData[index].path;
//				}
//			}
//		}
	}
};

SvgChartActionView.prototype.drawPanelAction = function()
{
	if (this.chart.mainTechnical.data == null) {
		return;
	}
	// console.log("========SvgChartActionView drawPanelAction start===========");
	// var mapping = this.chart.viewList[this.chart.viewList.length - 1].mapping;
	var mapping = this.chart.viewList[0].mapping;
	var paddingBottom = 5;
	if(this.chart.viewList[this.chart.viewList.length - 1].mapping){
		paddingBottom = this.chart.viewList[this.chart.viewList.length - 1].mapping.paddingBottom;
	}
	if (this.panelActionStatus != null) {
		var index = Math.max(mapping.dataStartIndex, Math.min(mapping.dataStartIndex + mapping.dataLength + mapping.dataBlank - 1, Math.round(mapping.unTransformIndex(this.panelActionStatus.x))));
		// if (this.index == index) {
			// return;
		// }
	} else {
		var index = mapping.dataStartIndex + mapping.dataLength + mapping.dataBlank - 1;
	}

	var util = new SvgChartUtil();

	// Get panel context
	var context = this.actionCanvas;
	SvgChartDrawUtil.clear(context);
	var context_point = this.actionCanvas1;
	SvgChartDrawUtil.clear(context_point);
	this.resetColor();

	if (this.chart.mainTechnical.data != null) {

		var x0 = mapping.paddingLeft;
		var x1 = mapping.drawAreaWidth - mapping.paddingRight;
//		var y0 = mapping.paddingTop - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
//		// var y1 = this.drawAreaHeight - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING - SvgChartGlobal.LAYOUT_PANEL_HAXIS_HEIGHT + 0.5;
//		var y1 = this.drawAreaHeight - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING - 0.5;
//		if (this.chart.viewList.length == 1) {
//			y1 -= SvgChartGlobal.LAYOUT_PANEL_HAXIS_HEIGHT;
//		}
		var y0 = mapping.paddingTop + 0.5;
		var y1;
		if(mapping.benchmark){
			y1 = mapping.drawAreaHeight - mapping.paddingBottom - 0.5;
		}else{
			y1 = this.drawAreaHeight - paddingBottom - 0.5;
		}
//		if (this.chart.viewList.length == 1) {
//			y1 -= SvgChartGlobal.LAYOUT_PANEL_HAXIS_HEIGHT;
//		}

		// Draw cross
		var x = mapping.transformIndex(index) + 0.5;
		// var y = Math.max(y0, Math.min(y1 , this.panelActionStatus.y)) + 0.5;
//		var ax = this.panelActionStatus.ax;
//		var ay = this.panelActionStatus.ay;

		// console.log("SvgChartActionView ax : " + ax + " ,ay : " + ay);

//		if (this.chart.mainTechnical.data[index] == null) {
//			return;
//		}
		
		if (this.chart.mainTechnical.data[index] == null || this.chart.mainTechnical.data[index]["close"] == null) {
			return;
		}
		
		var v = this.chart.mainTechnical.data[index]["close"];
//		if (v == null) return;

		var start = Math.max(0, mapping.dataStartIndex);
		var end = mapping.dataStartIndex + mapping.dataLength;
		for(var k = start; k < end; k++){
			if(this.chart.mainTechnical.data[k] && this.chart.mainTechnical.data[k]["close"] != null) break;
		}
		var startv = this.chart.mainTechnical.data[k]["close"];

		if (mapping.benchmark == true) {
			if(!isNaN(startv))
				v = (v / startv - 1) * 100;
			else
				v = null;
		}
		var y = mapping.transformValue(v, this.chart.mainTechnical.vAxis.vAxis) + 0.5;
		var offset = 0;

		// dataList
		var data = this.chart.mainTechnical.data[index];

		var date;
		var date;

		if(data != null) {
			date = this.chart.detailTimeFormat(data.date);
			var date_xAxis = this.chart.timeFormat(data.date);
		}

//		this.actionDiv.style.cursor = "";
//		this.dataDiv.className = "SvgChartViewData";
//		this.dataDiv.style.backgroundColor = "#FFFFFF";
		if (this.panelActionStatus != null) {
			SvgChartDrawUtil.line(context, x, y0, x, y1, this.mapping.panel.crossColor);
			SvgChartDrawUtil.line(context, x0, y, x1 + 10, y, this.mapping.panel.crossColor);
		}
		
		var ohlc = [];
		var txt = "";
		var txt_len = 0;
		if (this.chart.ohlcFuc == null) {
			txt = "<span>" + date + "</span>";
		} else {
			ohlc.push(date);
		}

		for (var i = 0; i < this.chart.viewList.length; i++) {
			var mapping = this.chart.viewList[i].mapping;
			
			for (var t = -1; t < this.chart.viewList[i].technicalList.length; t++) {
				if (t == -1) {
					if (i > 0 || this.chart.ohlcFuc == null) continue;
				}
				
				var technical = this.chart.viewList[i].technicalList[t < 0 ? 0 : t];

				// if (technical.type == "sub" && this.chart.mainMapping.benchmark) {
				// 	continue;
				// }
				
				if (technical.name != "Ichimoku" && data == null) {
					continue;
				}

				if (technical.type == "dummy") continue;
				if (mapping.benchmark && technical.dataLoadType != SvgChartTechnical.DATA_LOAD_TYPE_URL) continue;

//				var elements = technical.elements;
//				if (elements != null) {
//
//					element = elements[index];
//					if (element != null) {
//
//						if (technical.dataColor != null && technical.dataColorSelect != null) {
//							for (var d = 0; d < technical.dataColor.length; d++) {
//								if (SvgChartDrawUtil.getAttr(element, "fill") == technical.dataColor[d]) {
//									SvgChartDrawUtil.setAttr(element, "fill", technical.dataColorSelect[d]);
//								}
//							}
//						}
//					}
//				}

				var labels = technical.labels;
				var dataColor = technical.dataColor;
				if (i == 0 && (mapping.benchmark == true || technical.vAxis.vAxis > 0)) {
					labels = [{label : technical.code, data : technical.dataLoadName}];
				}
				if (i == 0 && t < 0) {
					labels = [{label : "open", data : "open"}, {label : "high", data : "high"}, {label : "low", data : "low"}, {label : "close", data : "close"}];
				}
				var type = "volume";
//				if (technical.name == "volume") {
//					type = "volume";
//				} else if(ForexCode[technical.code] != undefined){
//					type = "price3";
//				} else if (this.chart.exchange == "US") {
//					type = "price2";
//				} else if (technical.code == "jbrl" || technical.code == "G7") {
//					type = "price3";
//				} else if (technical.code != null && technical.code.substring(0, 1) != ".") {
//					type = "price";
//				} else if (technical.code == null && this.chart.mainTechnical.code.substring(0, 1) != ".") {
//					type = "price";
//				}

                if(technical == this.chart.mainTechnical){
	                if("undefined" != typeof(dataCache) && dataCache != null){
	                    if(dataCache["33"] != null && dataCache["9"] != null){
	                        var price_section = parseFloat(get_price_section(dataCache["33"], dataCache["9"]));
	
	                        if (!isNaN(price_section) && price_section < 1) {
	                            type = "price";
	                        }
	                    }
	                }
	                if("undefined" != typeof(websocket33) && websocket33 != null){
	                    if(websocket33[technical.code] != null && websocket9[technical.code] != null){
	                        var price_section = parseFloat(get_price_section(websocket33[technical.code], websocket9[technical.code]));
	
	                        if (!isNaN(price_section) && price_section < 1) {
	                            type = "price";
	                        }
	                    }
	                }
                }

				// var br = "&nbsp;";
				var br = "";
				for (var j = 0; j < labels.length; j++) {

					var label;
                    if (technical.stock_name != null) {
                        label = technical.stock_name;
                    } else if (i > 0 || t > 0 || (technical.name == "candle" && mapping.benchmark == false)) {
						label = this.chart.getLabel(labels[j].label);
						// label = labels[j].label;
					} else {
						label = this.chart.name;
						// dataColor = SvgChartTechnical.technicalMaster["line"].dataColor;
					}

					if (technical.params != null && technical.labelShowParam) {
						label = label + '(' + technical.params[j] + ')';
					}
					
					var showv;
					if (technical.dataLoadType == SvgChartTechnical.DATA_ANALYSIS) {
						v = technical.data[labels[j].data][index];
						startv = technical.data[technical.labels[j].data][start];
					} else if (technical.dataLoadType == SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN) {
						v = data[labels[j].data];
						startv = this.chart.mainTechnical.data[start][labels[j].data];
					} else {
						if (technical.data[index] == null) {
							txt = txt + "</span></div>";
							continue;
						}

						v = technical.data[index][labels[j].data];
						if (v == null) {
							txt = txt + "</span></div>";
							continue;
						}
						
						for(var k = start; k < end; k++){
							if(technical.data[k] && technical.data[k][labels[j].data] != null) break;
						}

						startv = technical.data[k][labels[j].data];
					}

					showv = MST.SvgChart.numberFormatFunc(v, type);

					if (t >= 0 && technical.vAxis.vAxis == 0 && mapping.benchmark == true) {
						if(!isNaN(startv) && startv != null) {
							var percentv = (v / startv - 1) * 100;
							showv = percentv.toFixed(2) + "%(" + showv + ")";
							v = percentv;
						}
						else{
							for(var k = start; k < start + mapping.dataLength; k++) {
								if(technical.data[k] && technical.data[k][labels[j].data]) {
									break;
								}
							}
							if(k != start + mapping.dataLength && index >= k){
								var sv = technical.data[k][labels[j].data];
								var percentv = (v / sv - 1) * 100;
								showv = percentv.toFixed(2) + "%(" + showv + ")";
								v = percentv;
							}else{
								v = null;
							}							
						}
					}
					
					if (i == 0 && t < 0) {
						showv = MST.SvgChart.numberFormatFunc(v, type);
						ohlc.push(showv);
					}

					if (true) {
						if (technical.name == "candle" && mapping.benchmark == false) {
							txt = txt + br + "<div class='mst_tooltip'><span></span><span>" + label + ": ";
						} else {
							txt = txt + br +"<div class='mst_tooltip'><span style='color:" + dataColor[j] + "'></span>";
							txt = txt +"<span>" + label + ": ";
						}

						if (v != null) {
							txt = txt + "<b>" + showv + "</b></span></div>";
							txt_len += 1;

//							if (this.panelActionStatus != null && (technical.projectorType == "line" || mapping.benchmark)) {
//								var y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;
//								
//								for(var n = 0; n < i; n++) {
//									y += this.chart.viewList[n].mapping.drawAreaHeight;
//								}
//
//								SvgChartDrawUtil.drawPoint(context_point, x, y, dataColor[j], 4);
//							}
						} else {
							txt = txt + "</span></div>";
						}
						
					}
					if (v != null && this.panelActionStatus != null) {
						var y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;
						
						for(var n = 0; n < i; n++) {
							y += this.chart.viewList[n].mapping.drawAreaHeight;
						}

						SvgChartDrawUtil.drawPoint(context_point, x, y, dataColor[j], 4);
					}
					
					var yAxisV;
					// if (i == 0 && t == 0 && j == 0) {
					if (this.panelActionStatus != null && i == 0 && t == 0) {
						if (mapping.benchmark == true) {
							yAxisV = percentv.toFixed(2) + "%";
						} else {
							yAxisV = MST.SvgChart.numberFormatFunc(v, type);
						}
						
//						this.yAxisDiv.innerHTML = yAxisV;
						this.yAxisDiv.innerHTML = "<i></i>" + yAxisV;
//						if (this.yAxisDiv.clientWidth < (mapping.paddingRight - 7)) {
//							this.yAxisDiv.style.width = (mapping.paddingRight - 7) + "px";
//						}
						this.yAxisDiv.style.top = y + offset - this.yAxisDiv.clientHeight / 2 + "px";
						this.yAxisDiv.style.left = mapping.drawAreaWidth - this.yAxisDiv.clientWidth + "px";
						this.yAxisDiv.style.visibility = "visible";
					}
				}
			}
		}

		if (this.panelActionStatus != null && date_xAxis != null) {
			this.xAxisDiv.innerHTML = date_xAxis;
			this.xAxisDiv.style.top = this.chart.viewList[0].mapping.drawAreaHeight + offset - this.xAxisDiv.clientHeight + "px";
			this.xAxisDiv.style.left = x - this.xAxisDiv.clientWidth / 2 + "px";
			this.xAxisDiv.style.visibility = "visible";
		}
		
		if (txt != "" && txt_len > 0) {
			if (this.chart.tipDiv) {
				this.chart.tipDiv.innerHTML = txt;
                chartNewsHeightCalc();
			} else if (this.panelActionStatus != null) {
				this.dataDiv.innerHTML = txt;
	
				var w = this.dataDiv.clientWidth;
				var h = this.dataDiv.clientHeight;
				
				var ax = this.panelActionStatus.ax;
				var ay = this.panelActionStatus.ay;
	
				var t = ay + offset - 40 - h;
				if (t < offset + 10) t = ay + offset + 40;
				this.dataDiv.style.top = t + "px";
	
				if(x < (x1 - x0) / 2) {
					this.dataDiv.style.left = (ax + 40) + "px";
				}
				else {
					this.dataDiv.style.left = Math.max((ax - 40 - w), 0) + "px";
				}
				this.dataDiv.style.visibility = "visible";
			}
		}

	}
	
	if (this.chart.ohlcFuc != null) {
		this.chart.ohlcFuc(ohlc);
	}
	
	this.index = index;

    $('.chart_select_but ul li').each(function(index){
        if($(this).hasClass('chart_title_curr')){
            $(this).removeClass('chart_title_curr');
            $('.chart_cont_part > div').eq(index).removeClass('passage_show');
        }
    })
};

SvgChartActionView.prototype.showNews = function(news_list, newsType, x, y, newsItem)
{
	this.touchCount = 0;
	
	if (this.newsItem) {
//		this.newsItem.setAttribute("fill", "#fff");
		//this.newsItem.style.backgroundColor = "#014099";
	}
	
//	newsItem.setAttribute("fill", "#777");
	//newsItem.style.backgroundColor = "#000";

	this.newsItem = newsItem;
	
	this.panelActionStatus = null;
	this.drawPanelAction();
	
	var newsDiv = this.dataDiv;
	if (this.chart.newsDiv) {
		newsDiv = this.chart.newsDiv;
	}
	newsDiv.innerHTML = "";
	
	var box = document.createElement("ul");
//	box.className = "list_unstyled SvgChartViewDataBox";
	box.className = "list_unstyled";
//	if(this.dataDiv.className.indexOf("SvgChartViewNewsData") < 0){
//		this.dataDiv.className += " SvgChartViewNewsData";
//	}	
	newsDiv.appendChild(box);

	var news_list_all = news_list;
	news_list = [];
	for (var j = 0; j < news_list_all.length; j++) {
		var news = news_list_all[j];
		news_list.push(news);
	}
	
	var show_8 = 400;
	for (var i = 0; i < news_list.length; i++) {
		if (i >= show_8) break;
		var news = news_list[i];
		var news_type = news.type;

		var item = document.createElement("li");
        item.className = news_type + '_line';
		box.appendChild(item);

		var leftDiv = document.createElement("div");
		var span = document.createElement('span');
        span.className = 'news_source';
		span.innerHTML = news.source;
        leftDiv.appendChild(span);
		item.appendChild(leftDiv);

		var rightDiv = document.createElement("div");
		item.appendChild(rightDiv);

		var span = document.createElement("span");
        span.className = 'news_time';
		rightDiv.appendChild(span);
//		var date = (new Date(news.date)).format('yyyy/MM/dd HH:mm');
		var date = (new Date(news.date)).format('yyyy/MM/dd');
		//span.innerHTML = "[" + date + "]";
		span.innerHTML = date;

		if (news.source == '空売'){
			var span = document.createElement("span");
			span.innerHTML = " " + news.title;
		} else {
			var span = document.createElement("a");
			var url = news.url == null ? '' : news.url;
			span.href = url;
			span.target = '_blank';
			span.innerHTML = news.title;
		}
    span.className = 'news_title';
		rightDiv.appendChild(span);		
	}
	
	if (news_list.length > show_8) {
		var box_a = document.createElement("div");
		box_a.className = "SvgChartViewDataBoxMoreBtn";
		newsDiv.appendChild(box_a);

		var a = document.createElement("a");
		a.setAttribute("href", "#");
		a.innerHTML = "その他" + (news_list.length - show_8) + "件";
		a.style.float = "right";
		box_a.appendChild(a);
		
		box = document.createElement("ul");
		box.className = "list_unstyled SvgChartViewDataBox SvgChartViewDataBoxMore";
		box.style.display = "none";
		newsDiv.appendChild(box);

		(function(box_a, box) {
			a.onclick = function() {
				box.style.display = "";
				box_a.style.display = "none";
			};
		})(box_a, box);

		for (var i = show_8; i < news_list.length; i++) {
			var news = news_list[i];
			var news_type = news.type;

			var item = document.createElement("li");
            item.className = news_type + '_line';
			box.appendChild(item);

			var leftDiv = document.createElement('div');
			var span = document.createElement('span');
            span.className = 'news_source';
			span.innerHTML = news.source;
            leftDiv.appendChild(span);
			item.appendChild(leftDiv);

			var rightDiv = document.createElement("div");
			item.appendChild(rightDiv);
			var span = document.createElement("span");
            span.className = 'news_time';
			rightDiv.appendChild(span);
//			var date = (new Date(news.date)).format('yyyy/MM/dd HH:mm');
			var date = (new Date(news.date)).format('yyyy/MM/dd');
			//span.innerHTML = "[" + date + "]";
			span.innerHTML = date;
			
			if (news.source == '空売') {
				var span = document.createElement("span");
				span.innerHTML = " " + news.title;
			}
			else{
				var span = document.createElement("a");
				var url = news.url == null ? '' : news.url;
				span.href = url;
				span.target = '_blank';
				span.innerHTML = news.title;
			}
			span.className = 'news_title';
			rightDiv.appendChild(span);
		}
	}
	

//	this.dataDiv.style.left = 100 + "px";
//	var w = this.dataDiv.clientWidth;
//	x = Math.max(x - w + 10, 0);
//	this.dataDiv.style.left = (x + 15) + "px";
//	
//	var h = this.dataDiv.clientHeight;
//	var t = Math.max(y - 25, 10);
//	this.dataDiv.style.top = t + "px";
//	
//	this.dataDiv.style.visibility = "visible";

	var obj = document.getElementById("news_start_end");
	if (obj != null) {
		var date_end = news_list[0].date;
		date_end = formatDate(date_end);
		var date_start = news_list[news_list.length - 1].date;
		date_start = formatDate(date_start);
		obj.innerHTML = date_start + "-" + date_end;
	}
//	newsSelectShow();
	function formatDate(date) {
		var d = new Date(date),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
				year = year.toString();
				year = year.substring(year.length - 2);
		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;
		return [year, month, day].join('/');
	}
};


MST.SvgChartActionView = SvgChartActionView;
}());