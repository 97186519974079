(function() {

var SvgChartGlobal = MST;
var SvgChartMapping = MST.SvgChartMapping;
var SvgChartUtil = MST.SvgChartUtil;
var SvgChartDrawUtil = MST.SvgChartDrawUtil;
var SvgChartTechnical = MST.SvgChartTechnical;
var SvgChartProjector = MST.SvgChartProjector;

var SvgChartView = function (chart, index) {
	var util = new SvgChartUtil();

	this.mapping = new SvgChartMapping();
	if (index == 0 && chart.benchmarks != null && chart.benchmarks.length > 0) {
		this.mapping.benchmark = true;
	}

	this.chart = chart;
	this.index = index;
	var techGroup = chart.techGroupList[index];

	var leftVaxisWidth = 0;
	if (chart.chartConfig.globalViewConfig.leftAxis) {
		leftVaxisWidth = techGroup[0].vAxis.width;
	}
	var rightVaxisWidth = 0;
	if (chart.chartConfig.globalViewConfig.rightAxis) {
		rightVaxisWidth = techGroup[0].vAxis.width;
	}
	
	this.vAxisParts = techGroup[0].vAxis.parts;
	this.vDecimals = techGroup[0].vAxis.decimals;

	// if (this.mapping.twoAxes) {
		// leftVaxisWidth = SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH;
		// rightVaxisWidth = SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH;
	// } else if (this.mapping.vAxisType == 0) {
		// leftVaxisWidth = SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH;
		// rightVaxisWidth = 0;
	// } else {
		// leftVaxisWidth = 0;
		// rightVaxisWidth = SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH;
	// }

	this.mapping.drawAreaWidth = this.chart.chartWidth;
	this.mapping.drawAreaHeight = techGroup[0].layout.height;

	var paddingTop = techGroup[0].layout.paddingTop;
	var paddingBottom = techGroup[0].layout.paddingBottom;
	var paddingLeft = techGroup[0].layout.paddingLeft;
	var paddingRight = techGroup[0].layout.paddingRight;

	if (index == 0) {
		paddingBottom += techGroup[0].hAxis.height
	}
	this.mapping.setPadding(paddingLeft + leftVaxisWidth,
			paddingRight + rightVaxisWidth,
			paddingTop,
			paddingBottom,
			0);
	this.mapping.panel = techGroup[0].panel;
	this.mapping.vAxis = techGroup[0].vAxis;
	this.mapping.hAxis = techGroup[0].hAxis;

	this.frameDiv = document.createElement("div");
	this.panelDiv = document.createElement("div");
	this.labelDiv = document.createElement("div");
	this.settingDiv = document.createElement("div");
	this.sketchDiv = document.createElement("div");
    this.multiTechDiv = document.createElement("div");

	this.frameDiv.className = "SvgChartViewFrame";
	this.panelDiv.className = "SvgChartViewPanel";
	this.labelDiv.className = "SvgChartViewLabelDiv";
	this.settingDiv.className = "SvgChartViewLabelDiv";

	this.panelDrawCanvas = SvgChartDrawUtil.createCanvas(this.panelDiv, this.chart.chartWidth, this.mapping.drawAreaHeight);
	this.sketchCanvas = SvgChartDrawUtil.createCanvas(this.sketchDiv, this.chart.chartWidth, this.mapping.drawAreaHeight);

	// Lable div
	this.labelDiv.style.position = "absolute";
	this.labelDiv.style.width = this.chart.chartWidth - 100 + "px";
	this.labelDiv.style.width = "100%";
	this.labelDiv.style.overflowX = "hidden";
	this.labelDiv.style.zIndex = 1;

	this.settingDiv.style.position = "absolute";
	this.settingDiv.style.zIndex = 10;

    this.multiTechDiv.style.position = "absolute";
    this.multiTechDiv.style.zIndex = 10;
    this.multiTechDiv.style.top = 5 + "px";
    this.multiTechDiv.style.left = this.chart.chartWidth - 107 + "px";
    this.multiTechDiv.style.display = "none";
  this.multiTechDiv.className ='SvgChartViewButtonDiv'

    var input = document.createElement("input");
    input.type = "button";
    input.style.width = "35px";
    input.value = "拆分";
    this.multiTechDiv.appendChild(input);
    var t = this;
    input.onclick = function() {
        // console.log("splitView " + t.index);
        chart.splitView(t.index);
    }

    var input = document.createElement("input");
    input.type = "button";
    input.style.width = "35px";
    input.value = "切换";
    this.multiTechDiv.appendChild(input);
    input.onclick = function() {
        t.mapping.benchmark = !t.mapping.benchmark;
        // console.log("benchmark " +  t.mapping.benchmark);
        t.mapping.updated = true;

        for (var i = 0; i < t.technicalList.length; i++) {
            if (t.mapping.benchmark) {
                t.technicalList[i].vAxis.vAxis = 0;
            } else {
                t.technicalList[i].vAxis.vAxis = i;
            }
        }

        t.draw();
    }

    // Panel div
	this.panelDiv.style.width = "100%";
	this.panelDiv.style.height = this.mapping.drawAreaHeight + "px";

	this.sketchDiv.style.width = "100%";
	this.sketchDiv.style.height = this.mapping.drawAreaHeight + "px";
	this.sketchDiv.style.position = "absolute";
	this.sketchDiv.style.zIndex = 1;

	this.frameDiv.appendChild(this.labelDiv);
	this.frameDiv.appendChild(this.settingDiv);
    this.frameDiv.appendChild(this.multiTechDiv);
	this.frameDiv.appendChild(this.sketchDiv);
	this.frameDiv.appendChild(this.panelDiv);

    this.frameDiv.style.position = "relative";
    this.top = 0;

	if(this.index == 0) {
		this.chart.mainMapping = this.mapping;
	}

	chart.viewDiv.appendChild(this.frameDiv);

	this.setTechnicalList(techGroup);
	
	this.sketchList = [];
};

SvgChartView.prototype.show = function(){
	this.frameDiv.style.display = "block";
	this.panelDiv.style.display = "block";
	this.labelDiv.style.display = "block";
	this.settingDiv.style.display = "block";
}

SvgChartView.prototype.hidden = function(){
	this.frameDiv.style.display = "none";
	this.panelDiv.style.display = "none";
	this.labelDiv.style.display = "none";
	this.settingDiv.style.display = "none";
}

var SvgChartTechnicalLabel = function (technical) {
	if (technical.view.mapping.benchmark == true) {
		if (technical.dataLoadType != SvgChartTechnical.DATA_LOAD_TYPE_URL) return;
	}
	
	technical.technicalLabel = this;
	this.technical = technical;

	var techLabelDiv = document.createElement("div");
	this.techLabelDiv = techLabelDiv;
	techLabelDiv.className = "SvgChartViewLabel";
	if (technical.vAxis.vAxis) {
		// techLabelDiv.style.float = "right";
	}

	technical.view.labelDiv.appendChild(techLabelDiv);
	
	this.techColorDivList = [];
	this.techSettingList = [];

	var details = technical.labels;
	if (technical.dataColorLabel != null && technical.dataColorLabel.length > 0) {
		details = technical.dataColorLabel;
	}
	
	for (var i = 0; i < details.length; i++) {
		var techColorDiv = document.createElement("div");
		techColorDiv.className = "SvgChartViewLabelColor";
		techLabelDiv.appendChild(techColorDiv);

		var colorPoint = document.createElement("div");
		colorPoint.style.backgroundColor = technical.dataColor[i];
		colorPoint.className = "SvgChartViewColorPoint";
		techColorDiv.appendChild(colorPoint);

		var techText;
		if (technical.isMainTechnical()) {
			techText = technical.view.chart.name;
		} else if (technical.code != null && technical.dataLoadType != SvgChartTechnical.DATA_ANALYSIS) {
//			if(technical.view.chart.indicatorViewBenchmarks[technical.code]){
//				techText = technical.view.chart.indicatorViewBenchmarks[technical.code].name;
//			}
//			else{
//				techText = technical.code
//			}
			techText = technical.view.chart.getLabel(technical.code);
        } else if (technical.stock_name != null) {
            techText = technical.stock_name;
		} else {
			techText = technical.view.chart.getLabel(technical.labels[i].label);
		}
		
		if (technical.params != null && technical.labelShowParam) {
			techText = techText + '(' + technical.params[i] + ')';
		}
		
		var techColorText = document.createTextNode(techText);
		techColorDiv.appendChild(techColorText);
		// techColorDiv.style.borderLeftColor = technical.dataColor[i];
		
		this.techColorDivList.push(techColorDiv);
		
		var technicalLabelDetail = new SvgChartTechnicalLabelDetail(technical, i ,techColorDiv);
		this.techSettingList.push(technicalLabelDetail.techSetting);
		
		if (!technical.colorIsConstant && technical.view.chart.chartConfig.actionView.active && technical.view.chart.chartConfig.globalViewConfig.labelActive) {
			var util = new SvgChartUtil();
			util.attachTouchEvent(technicalLabelDetail, techColorDiv);
//			util.attachTouchEvent(technicalLabelDetail, technicalLabelDetail.techSetting.techSettingDiv);
		}
	}
};

var SvgChartTechnicalLabelDetail = function (technical, index ,techColorDiv) {
	this.technical = technical;	
	this.index = index;
	this.techColorDiv = techColorDiv;
	if (!technical.colorIsConstant) {
		if(!technical.settingPanel){
			technical.settingPanel = new Array();
		}
		if(technical.settingPanel[index]){
			this.techSetting = technical.settingPanel[index];
		}
		else{
			this.techSetting = new SvgChartTechnicalSetting(technical, index);
			technical.settingPanel[index] = this.techSetting;
		}		
	}
};

SvgChartTechnicalLabelDetail.prototype.onTouchEvent = function(target, pharse, cursorCoords)
{
	if(this.technical.view.mapping.status != SvgChartTechnical.DATA_STATUS_OK) {
		return true;
	}

	if (pharse == "touchstart" || pharse == "touchmove") {
		this.techSetting.techSettingDiv.style.visibility = "visible";
		this.techSetting.techSettingDiv.style.left = this.techColorDiv.offsetLeft + "px";
		this.techSetting.techSettingDiv.style.top = this.techColorDiv.offsetTop + this.techColorDiv.offsetHeight + "px";
	} else if(pharse == "touchout") {
//		this.techSetting.techSettingDiv.style.visibility = "hidden";
	}

	return false;
};

var SvgChartTechnicalSetting = function (technical, index) {
	var childNodes = technical.view.settingDiv.childNodes;
	
	if(childNodes){
		var techSettingDiv = document.createElement("div");
		this.techSettingDiv = techSettingDiv;
		this.technical = technical;

		if (index != null) {
			technical.technicalSetting = this;
			this.index = index;

			this.techSettingDiv = techSettingDiv;
			techSettingDiv.style.visibility = "hidden";
			if (technical.params == null) {
				techSettingDiv.className = "SvgChartTechnicalSetting compare";
			} else if (technical.params != null && !technical.plural) {
				techSettingDiv.className = "SvgChartTechnicalSettingMultiParams " + technical.name;
			} else {
				techSettingDiv.className = "SvgChartTechnicalSetting";
			}
			
			techSettingDiv.style.position = "absolute";
			techSettingDiv.style.zIndex = 100;
			var label = technical.technicalLabel.techColorDivList[index];
			techSettingDiv.style.top = label.offsetTop + label.offsetHeight + "px";
			techSettingDiv.style.left = label.offsetLeft + "px";

			technical.view.settingDiv.appendChild(techSettingDiv);
		}

		if (!technical.isMainTechnical()) {
			if(technical.cancellable != false && Raphael.svg){
				var removeRect = document.createElement("span");
				var remove = document.createElement("span");
				removeRect.className = "SvgChartColorRect";
				removeRect.style.float = "left";
				remove.className = "SvgChartLabelRemove";
				removeRect.appendChild(remove);
				techSettingDiv.appendChild(removeRect);
				
				removeRect.onclick = function() {
					techSettingDiv.style.display = "none";
					if(technical.isBenchmarks){
						if (technical.view.chart.chartConfig.controller.showBenchmark) {
							technical.view.chart.controllerView.benchmarkControllerView.removeBenchmark(technical.code);
						}
					} else {
						if (technical.view.chart.chartConfig.controller.showIndicator) {
							technical.view.chart.controllerView.indicatorControllerView.techCount[technical.name]--;
						}
					}
					// while(technical.view.settingDiv.lastChild) {
					// 	technical.view.settingDiv.removeChild(technical.view.settingDiv.lastChild);
					// }

					technical.view.chart.removeTechnical(technical.techId);
				}
			}
		}

		if (technical.params != null) {
			var ul = document.createElement("ul");
//			techSettingDiv.appendChild(ul);
			
			this.paramsText = new Array();
			for (var i = 0; i < technical.params.length; i++) {
		
				var li = document.createElement("li");
				ul.appendChild(li);

				var span = document.createElement("span");
//				span.className = "label";
				span.innerHTML = technical.view.chart.getLabel(technical.paramLabels[i]);
				li.appendChild(span);

				var input = document.createElement("input");
				input.type = "text";
				input.style.width = "20px";
				input.value = technical.params[i];
				input.maxLength = technical.paramMaxLengths[i];
				techSettingDiv.appendChild(input);
				li.appendChild(input);
				this.paramsText[i] = input;
				
//				input.onclick = function(e) {
//					this.focus();
//					e.stopPropagation();
//				};
		
				(function(technical, i, input) {
					input.onkeydown = function(k) {
						var k = k || window.event; 
						if (k.keyCode == 13) {
							var n = parseFloat(input.value);
							
							if (!isNaN(n) && n > 0) {
								technical.params[i] = n;
								var chart = technical.view.chart;
								chart.analysis();
								chart.drawAll();

								if(technical.settingPanel && technical.settingPanel.length > 0) {
									for(var m = 0; m < technical.settingPanel.length; m++) {
										technical.settingPanel[m].changeParamsText(i, n);
									}
								}
							}
						}
					};

					input.onblur = function(k) {
						var n = parseFloat(input.value);
							
						if (!isNaN(n) && n > 0) {
							if(n != technical.params[i]){
								technical.params[i] = n;
								var chart = technical.view.chart;
								chart.analysis();
								chart.drawAll();

								// update technical's params on main chart
								if(technical.settingPanel && technical.settingPanel.length > 0) {
									for(var m = 0; m < technical.settingPanel.length; m++) {
										technical.settingPanel[m].changeParamsText(i, n);
									}
								}
							}							
						}
					};
				})(technical, i, input);
			}
		}

		if (technical.params != null && !technical.plural) {
			techSettingDiv.appendChild(ul);
		}

		for (var cIndex = 0; cIndex < technical.dataColor.length; cIndex++) {
			
			if (index != null && index != cIndex && technical.labels.length == technical.dataColor.length) {
				continue;
			}
			
			var colorDiv = document.createElement("div");
			colorDiv.style.paddingLeft = "25px";
			if (technical.params != null) {
				colorDiv.className = "SvgChartColorDiv";
			} else {
				colorDiv.className = "SvgChartColorDiv NoParamsColorDiv";
				colorDiv.style.paddingLeft = "0";
			}

			
			techSettingDiv.appendChild(colorDiv);

			var changeDiv = document.createElement("div");
			changeDiv.style.paddingLeft = "0px";
			colorDiv.appendChild(changeDiv);

			var colorPanel = document.createElement("span");
			colorPanel.className = "SvgChartColorRect";
			// colorPanel.style.top = "4px";
			// colorPanel.style.marginRight = "20px";
			colorPanel.style.backgroundColor = technical.dataColor[cIndex];
			changeDiv.appendChild(colorPanel);
			this.colorPanel = colorPanel;
			
			var select = document.createElement("i");
			colorPanel.appendChild(select);
			this.changeDiv = changeDiv;
			
//			if (dataColorLabels.length > 1 && index == null) {
			if (!technical.plural) {
				var span = document.createElement("span");
				span.className = "label";
//				span.style.color = technical.dataColor[cIndex];
				span.innerHTML = "label";
//				span.innerHTML = technical.view.chart.getLabel(technical.labels[cIndex].label);
				changeDiv.appendChild(span);
			}
			
			if (technical.plural) {
				changeDiv.appendChild(ul);
			}

			var util = new SvgChartUtil();
	//		util.attachClickEvent(this, index, colorRect);
			
			if (index != null) {
				(function (technical, index){
					colorPanel.onclick = function() {
						technical.view.colorSelect.show(technical, index);
					}
				})(technical, cIndex);
			} else {
				(function (colorPanel, technical, index){
					colorPanel.onclick = function() {
						technical.view.chart.controllerView.indicatorControllerView.colorSelect.show(technical, index, colorPanel);
					}
				})(colorPanel, technical, cIndex);
			}
			
			var lines = ['S', 'M', 'L'];
			for (var i = 0; i < lines.length; i++) {
				var border = document.createElement("span");
				if (SvgChartGlobal.LINE_WIDTH[i] == technical.lineWidth[cIndex]) {
					border.className = "SvgChartBorder";
				}
				var colorRect = document.createElement("span");
				colorRect.className = "SvgChartLine SvgChartLine" + lines[i];
				colorRect.style.borderTopColor = technical.dataColor[cIndex];
//				colorRect.innerHTML = lines[i];
				border.appendChild(colorRect);
				changeDiv.appendChild(border);
		
				util.attachClickEvent(this, i, colorRect);
				(function(technical, index, i, input, colorDiv) {
					input.onclick = function(k) {
						technical.lineWidth[index] = SvgChartGlobal.LINE_WIDTH[i];

						var child = colorDiv.childNodes;
						for(var lIndex = child.length - 3; lIndex < child.length; lIndex++){							
							if(lIndex == i + child.length - 3){
								child[lIndex].className = "SvgChartBorder";
							}
							else{
								child[lIndex].className = "";
							}
						}
						if(technical.settingPanel[index]){
							technical.settingPanel[index].changeLineBorder(i);
						}
						
						technical.view.chart.drawAll();
					};
				})(technical, cIndex, i, colorRect, changeDiv);
			}
		}
	}
};

SvgChartTechnicalSetting.prototype.changeColorPanel = function(color) {
	if(this.colorPanel){
		this.colorPanel.style.backgroundColor = color; 
	}
	if(this.changeDiv){
		var child = this.changeDiv.childNodes;
		for(var i = 1; i < child.length; i++){
			var lineBorder = child[i].childNodes[0];
			if(lineBorder && lineBorder.style){
				lineBorder.style.borderTopColor = color;
			}
		}
	}
}

SvgChartTechnicalSetting.prototype.changeLineBorder = function(index) {
	if(this.changeDiv){
		var child = this.changeDiv.childNodes;
		for(var lIndex = child.length - 3; lIndex < child.length; lIndex++){							
			if(lIndex == index + child.length - 3){
				child[lIndex].className = "SvgChartBorder";
			}
			else{
				child[lIndex].className = "";
			}
		}
	}
}

SvgChartTechnicalSetting.prototype.changeParamsText = function(index, value) {
	if(this.paramsText && this.paramsText.length > 0){
		if(index < this.paramsText.length){
			this.paramsText[index].value = value;
		}
	}
}

SvgChartTechnicalSetting.prototype.onClickEvent = function(target, index) {
	if (index != null) {
		this.technical.view.colorSelect.show(this.technical, index);
	} else {
		this.technical.view.chart.controllerView.indicatorControllerView.colorSelect.show(this.technical, index);
	}
	this.technical.userColor = true;
};

var SvgChartColorSelect = function (view) {
	this.colorSelectOutDiv = document.createElement("div");
	this.colorSelectOutDiv.style.position = "absolute";
//	this.colorSelectOutDiv.style.visibility = "hidden";
	this.colorSelectOutDiv.style.display = "none";
	this.colorSelectOutDiv.style.paddingTop = "15px";
	this.colorSelectOutDiv.style.zIndex = 9;
	if (view.settingDiv != null) {
		view.settingDiv.appendChild(this.colorSelectOutDiv);
	} else {
		view.labelDiv.appendChild(this.colorSelectOutDiv);
	}

	this.colorSelectDiv = document.createElement("div");
	// this.colorSelectDiv.style.position = "absolute";
	// this.colorSelectDiv.style.visibility = "hidden";
	this.colorSelectDiv.className = "SvgChartColorSelect clearfix";
	// this.colorSelectDiv.style.zIndex = 9;
	this.colorSelectOutDiv.appendChild(this.colorSelectDiv);
	
	var arrowDiv =  document.createElement("div");
	arrowDiv.className = "arrow arrow_color";
	this.colorSelectOutDiv.appendChild(arrowDiv);

//	var util = new SvgChartUtil();
//	util.attachTouchEvent(this, this.colorSelectDiv);
};

SvgChartColorSelect.prototype.show = function(technical, index, techColorRect) {
	this.technical = technical;
	this.index = index;
	
	if (techColorRect == null) {
		technical.technicalLabel.techSettingList[index].techSettingDiv.style.visibility = "hidden";

//		this.colorSelectOutDiv.style.visibility = "visible";
		this.colorSelectOutDiv.style.display = "block";
		this.colorSelectOutDiv.style.left = technical.technicalLabel.techColorDivList[index].offsetLeft + "px";
		this.colorSelectOutDiv.style.top = technical.technicalLabel.techSettingList[index].techSettingDiv.offsetTop - 15 + "px";
	} else {
//		this.colorSelectOutDiv.style.visibility = "visible";
		this.colorSelectOutDiv.style.display = "block";
		// this.colorSelectDiv.style.left = 5 + "px";
		// this.colorSelectDiv.style.top = techColorRect.offsetTop + "px";
		var topX = techColorRect.offsetTop - this.viewDiv.scrollTop + SvgChartGlobal.RANGE_VIEW_HEIGHT - 15;
		var leftX = techColorRect.offsetLeft - 25;
		this.colorSelectOutDiv.style.top = topX + "px";
		this.colorSelectOutDiv.style.left = leftX + "px";
	}

	var objs = this.colorSelectDiv.childNodes;
	for (var i = objs.length - 1; i >= 0; i--) {
		this.colorSelectDiv.removeChild(objs[i]);
	}
	
	for (var i = 0; i < SvgChartGlobal.COLOR_PANEL.length; i++) {		
		var colorRect = document.createElement("span");
		colorRect.className = "SvgChartColorRect";
		colorRect.style.backgroundColor = SvgChartGlobal.COLOR_PANEL[i];
		this.colorSelectDiv.appendChild(colorRect);
		
		if (SvgChartGlobal.COLOR_PANEL[i] == technical.dataColor[index]) {
			var select = document.createElement("i");
			colorRect.appendChild(select);
		}

		var util = new SvgChartUtil();
		util.attachClickEvent(this, i, colorRect);
	}
};

SvgChartColorSelect.prototype.onClickEvent = function(target, param) {
	this.technical.dataColor[this.index] = SvgChartGlobal.COLOR_PANEL[param];
	this.technical.userColor = true;
//	this.colorSelectOutDiv.style.visibility = "hidden";
	this.colorSelectOutDiv.style.display = "none";
	this.colorSelectOutDiv.style.top = 0 + "px";
	this.colorSelectOutDiv.style.left = 0 + "px";
	this.technical.view.chart.drawAll();
	this.technical.settingPanel[this.index].changeColorPanel(SvgChartGlobal.COLOR_PANEL[param]);
	return false;
};


SvgChartColorSelect.prototype.onTouchEvent = function(target, pharse, cursorCoords)
{
	if(this.technical.view.mapping.status != SvgChartTechnical.DATA_STATUS_OK) {
		return true;
	}

	if(pharse == "touchstart") {
//		this.colorSelectOutDiv.style.visibility = "visible";
		this.colorSelectOutDiv.style.display = "block";
	} else if(pharse == "touchout") {
//		this.colorSelectOutDiv.style.visibility = "hidden";
//		this.colorSelectOutDiv.style.display = "none";
//		this.colorSelectOutDiv.style.top = 0 + "px";
//		this.colorSelectOutDiv.style.left = 0 + "px";
	}

	return false;
};


SvgChartView.prototype.setTechnicalList = function(technicalList)
{
	// console.log("========setTechnicalList start===========");
	for (var i = 0; i < technicalList.length; i++) {
		technicalList[i].view = this;
	}

	this.technicalList = technicalList;

	if (technicalList.length > 1) {
        this.multiTechDiv.style.display = "";
    } else {
        this.multiTechDiv.style.display = "none";
        this.mapping.benchmark = false;
    }
	
//	if (technicalList[0].isMainTechnical()) {
//		var benchmark = false;
//		for (var t = 1; t < technicalList.length; t++) {
//			var tech = technicalList[t];
//			if (tech.isBenchmarkTechnical()) {
//				benchmark = true;
//			}
//		}
//		this.mapping.benchmark = benchmark;
//	}
	
	// Create technical label
	while(this.labelDiv.lastChild) {
		this.labelDiv.removeChild(this.labelDiv.lastChild);
	}
	
	var colors = [];
	var isOutOfColor = false;
	for (var t = 1; t < SvgChartGlobal.COLOR_PANEL.length; t++) {
		colors.push(t);
	}
	
//	// saved in chart.setBenchmarksIndicators
//	for (var t = 1; t < this.technicalList.length; t++) {
//		if (this.technicalList[t].code != null) {
//			var dataColor = this.chart.color_panel_cache[this.technicalList[t].code];
//			if (dataColor != null) {
//				this.technicalList[t].dataColor = dataColor;
//			}
//		}
//	}

	for (var t = 0; t < this.technicalList.length; t++) {
		for (var i = 0; i < this.technicalList[t].dataColor.length; i++) {
			for (var j = colors.length - 1; j >= 0; j--) {
				if (this.technicalList[t].dataColor[i] == SvgChartGlobal.COLOR_PANEL[colors[j]]) {
					colors.splice(j, 1);
				}
			}
		}
	}
	
	var colorIndex = 0;
	for(var t = 1; t < this.technicalList.length; t++) {
//		if (this.mapping.benchmark) {
			if (this.technicalList[t].projectorType == "line"
				&& this.technicalList[t].dataColor[0] == SvgChartGlobal.COLOR_PANEL[0]
				&& !this.technicalList[t].userColor) {
//				if(SvgChartGlobal.COLOR_PANEL_TMP == undefined || SvgChartGlobal.COLOR_PANEL_TMP.length == 0){
//						SvgChartGlobal.COLOR_PANEL_TMP = SvgChartGlobal.COLOR_PANEL.slice(1);
//					}
//				
//					for(var p = 0; p < this.technicalList[t].labels.length; p++){
//						this.technicalList[t].dataColor[p] = SvgChartGlobal.COLOR_PANEL_TMP.shift();
//					}
				
//					 this.technicalList[t].dataColor = [SvgChartGlobal.COLOR_PANEL[colors[colorIndex]]];
				var ci; 
				if (colorIndex < colors.length) {
					 ci = colors[colorIndex];
				 } else {
					 ci = t % (SvgChartGlobal.COLOR_PANEL.length - 1) + 1;
				 }
				 this.technicalList[t].dataColor = [SvgChartGlobal.COLOR_PANEL[ci]];
				 colorIndex++;
			}
//		}
	}
	
	if (!this.chart.chartConfig.globalViewConfig.showLabel) return;

	for (var t = 0; t < this.technicalList.length; t++) {
		var technicalLabel = new SvgChartTechnicalLabel(technicalList[t]);
		if (this.index == 0 && t == 0
			&& ((this.chart.mainTechnical.name == "candle" && !this.mapping.benchmark)
					|| this.chart.chartConfig.type != 'PC')) {
			technicalLabel.techLabelDiv.style.display = "none";
		}
	}
	
	this.colorSelect = new SvgChartColorSelect(this);
	
	return;

	// var techLabelDiv = document.createElement("div");
	// techLabelDiv.className = "SvgChartViewLabel";
	// techLabelDiv.style.float = "right";

	this.chart.colorIndex = 0;
	for(var t = 0; t < this.technicalList.length; t++) {
		if (this.technicalList[t].name == "line" || (this.mapping.benchmark == true)) {
			this.technicalList[t].dataColor = [SvgChartGlobal.COLOR_PANEL[this.chart.colorIndex]];
			this.chart.colorIndex++;
		} else {
			var technicalMaster = SvgChartTechnical.technicalMaster[this.technicalList[t].name];
			this.technicalList[t].dataColor = technicalMaster.dataColor;
		}


		// if (this.chart.showIndicator == false) {
			// continue;
		// }
		// Create technical labels
		if(this.technicalList[t].label != null && this.chart.showLabel) {
			var techLabelDiv = document.createElement("div");
			techLabelDiv.className = "SvgChartViewLabel";

			if (this.technicalList[t].name == "pick") continue;

			if (this.mapping.benchmark == true) {
				if (this.technicalList[t].dataLoadType != SvgChartTechnical.DATA_LOAD_TYPE_URL) continue;

				var techColorDiv = document.createElement("div");
				techColorDiv.className = "SvgChartViewLabelColor";
				techColorDiv.style.backgroundColor = this.technicalList[t].dataColor[0];
				techLabelDiv.appendChild(techColorDiv);

				var techColorText;
				if (t > 0) {
					techColorText = document.createTextNode(this.technicalList[t].code);
				} else {
					// techColorText = document.createTextNode(this.technicalList[t].code);
					techColorText = document.createTextNode(this.chart.name);
				}
				techLabelDiv.appendChild(techColorText);

				if (this.chart.active && t > 0) {
					// var techRemoveDiv = document.createElement("div");
					// techRemoveDiv.style.cursor = "pointer";
					// techRemoveDiv.className = "SvgChartViewLabelRemove";

					var techRemoveDiv = document.createElement("a");
					techRemoveDiv.setAttribute("href", "#");
					techRemoveDiv.className = "SvgChartViewLabelRemove";
					techRemoveDiv.innerHTML = "&nbsp;&nbsp;";

					techLabelDiv.appendChild(techRemoveDiv);
					var util = new SvgChartUtil();
					util.attachClickEvent(this.chart.indicatorView, this.technicalList[t].code, techRemoveDiv);
				}

				this.labelDiv.appendChild(techLabelDiv);

			} else if (this.technicalList[t].dataColorLabel != null) {

				if (this.technicalList[t].dataColorLabel.length == 0) {
					for (var i = 0; i < this.technicalList[t].labels.length; i++) {
						var techColorDiv = document.createElement("div");
						techColorDiv.className = "SvgChartViewLabelColor";
						techColorDiv.style.backgroundColor = this.technicalList[t].dataColor[i];
						techLabelDiv.appendChild(techColorDiv);

						// var techColorText = document.createTextNode(this.chart.getLabel(this.technicalList[t].labels[i].label));
						var techText;
						if (this.index == 0 && t == 0) {
							// techText = this.technicalList[t].code;
							techText = this.chart.name;
						} else {
							// techText = this.technicalList[t].labels[i].label;
							techText = this.chart.getLabel(this.technicalList[t].labels[i].label);
						}
						var techColorText = document.createTextNode(techText);
						techLabelDiv.appendChild(techColorText);
					}
				} else {
					for (var i = 0; i < this.technicalList[t].dataColorLabel.length; i++) {
						var techColorDiv = document.createElement("div");
						techColorDiv.className = "SvgChartViewLabelColor";
						// techColorDiv.style.backgroundColor = this.technicalList[t].dataColor[i];
						techColorDiv.style.backgroundColor = "#0070bf";
						techLabelDiv.appendChild(techColorDiv);

						// var techColorText = document.createTextNode(this.chart.getLabel(this.technicalList[t].labels[i].label));
						var techText;
						if (this.index == 0 && t == 0) {
							// techText = this.technicalList[t].code;
							techText = this.chart.name;
						} else {
							// techText = this.technicalList[t].labels[i].label;
							techText = this.chart.getLabel(this.technicalList[t].labels[i].label);
						}
						var techColorText = document.createTextNode(techText);
						techLabelDiv.appendChild(techColorText);
					}
				}


				if (this.chart.active && (this.index > 0 || t > 0)){
					// var techRemoveDiv = document.createElement("div");
					// techRemoveDiv.style.cursor = "pointer";
					// techRemoveDiv.className = "SvgChartViewLabelRemove";

					var techRemoveDiv = document.createElement("a");
					techRemoveDiv.setAttribute("href", "#");
					techRemoveDiv.className = "SvgChartViewLabelRemove";
					techRemoveDiv.innerHTML = "&nbsp;&nbsp;";

					techLabelDiv.appendChild(techRemoveDiv);
					var util = new SvgChartUtil();
					util.attachClickEvent(this.chart.indicatorView, this.technicalList[t].name, techRemoveDiv);
				}
				this.labelDiv.appendChild(techLabelDiv);
			}
		}
	}

	// if (this.chart.active && this.index == 0 && this.labelDiv.lastChild) {
		// var splitText = document.createTextNode("|");
		// this.labelDiv.appendChild(splitText);
	// }
};

SvgChartView.prototype.loadData = function()
{
	for (var i = 0; i < this.technicalList.length; i++) {
		var tech = this.technicalList[i];
		tech.loadData();
	}
};

SvgChartView.prototype.onTechnicalDataStatusChange = function(tech)
{
	// console.log("========onTechnicalDataStatusChange start===========");
	var dataReady = true;
	var dataFailure = false;
	var noData = false;

	for (var i = 0; i < this.technicalList.length; i++) {
		var tech = this.technicalList[i];

		if (tech.dataLoadType != SvgChartTechnical.DATA_LOAD_TYPE_URL) continue;

		if(tech.dataStatus != SvgChartTechnical.DATA_STATUS_OK) {
			dataReady = false;

			if(tech.dataStatus == SvgChartTechnical.DATA_STATUS_FAILURE) {
				dataFailure = true;
			}

			if(tech.dataStatus == SvgChartTechnical.DATA_STATUS_NO_DATA) {
				noData = true;
			}
		}
	}
	// console.debug("dataReady : " + dataReady);
	// console.debug("dataFailure : " + dataFailure);
	// console.debug("noData : " + noData);

	if (noData == true) {
		this.mapping.setStatus(SvgChartTechnical.DATA_STATUS_NO_DATA);
	} else if (dataFailure == true) {
		this.mapping.setStatus(SvgChartTechnical.DATA_STATUS_FAILURE);
	}
	else if (dataReady == false) {
		this.mapping.setStatus(SvgChartTechnical.DATA_STATUS_LOADING);
	}
	else {
		this.mapping.setStatus(SvgChartTechnical.DATA_STATUS_OK);
		this.labelDiv.style.display = "";

		if(this.index == 0 && this.chart != null) {

			if (this.chart.timeFrameUpdated) {

				var ret = SvgChartUtil.getPeriod(this.chart.timeFrame, this.chart.mainTechnical.data);
				var startIndex = ret["startIndex"];
				var dataLength = ret["dataLength"];
				this.mapping.setDataRange(startIndex, dataLength);
				this.mapping.updated = true;
				this.chart.timeFrameUpdated = false;

			// } else if (this.mapping.dataLength == 0) {
				// // var preferDataLength = this.chart.defaultUnitCount;
				// var preferDataLength = Math.min(this.chart.defaultUnitCount, this.chart.mainTechnical.data.length);
//
				// this.mapping.setDataRange(this.chart.mainTechnical.data.length - preferDataLength, preferDataLength);
				// this.mapping.updated = true;
//
			// }

			} else if (this.chart.mainTechnical.name == "intraday") {
				// var exchangeCode = this.chart.exchange;
				// // hAxisValues = SvgChartUtil.exchangeHAxis(SvgChartGlobal.exchange[exchangeCode], this.chart.period.substring(0,1));
				// hAxisValues = SvgChartUtil.exchangeHAxis(SvgChartGlobal.exchange[exchangeCode], this.chart.detailTimeFormat, this.chart.mainTechnical.data[0].date);
				// // var preferDataLength = this.chart.defaultUnitCount;
				// var preferDataLength = hAxisValues[hAxisValues.length - 1].value + 1;
				var lastCloseStartIndex = this.chart.mainTechnical.lastCloseStartIndex;

				this.mapping.setDataRange(lastCloseStartIndex + 1, this.chart.mainTechnical.data.length - lastCloseStartIndex - 1);
				this.mapping.updated = true;
			} else if (this.chart.mainTechnical.name == "twoDays") {
				var twoDaysStartIndex = this.chart.mainTechnical.twoDaysStartIndex;

				this.mapping.setDataRange(twoDaysStartIndex, this.chart.mainTechnical.data.length - twoDaysStartIndex);
				this.mapping.updated = true;

			} else if (this.chart.period == "tick") {
				var twoDaysStartIndex = Math.max(this.chart.mainTechnical.data.length - 100, 0);

				this.mapping.setDataRange(twoDaysStartIndex, this.chart.mainTechnical.data.length - twoDaysStartIndex);
				this.mapping.updated = true;
			}

			// Update data range of sub charts
			for (var i = 1; i < this.chart.viewList.length; i++) {
				this.chart.viewList[i].mapping.setDataRange(this.mapping.dataStartIndex, this.mapping.dataLength);
				this.chart.viewList[i].mapping.updated = true;
			}
		// } else if (this.chart.mainTechnical.data != null && this.chart.mainTechnical.data.length > 0) {
			// var preferDataLength = this.chart.defaultUnitCount;
			// // this.mapping.setDataRange(this.chart.mainTechnical.data.length - preferDataLength, preferDataLength);
			// this.mapping.setDataRange(this.chart.mainMapping.dataStartIndex, this.chart.mainMapping.dataLength);
			// this.mapping.updated = true;
		}

		if(this.index == 0 && this.chart.period == "daily") {
			// if(this.chart.mainTechnical.data.length > 0) {
				// this.setTitle2(this.chart.mainTechnical.data[this.chart.mainTechnical.data.length - 1].date.substring(0, 10));
			// }
			// else {
				// this.setTitle2("");
			// }
		}

		// if current period =="1m" and chart type is not montain
//		if(!this.mapping.benchmark && this.index == 0 && this.chart.period == "1m" && this.chart.mainTechnical.name != "mountain"){
//			if(this.chart.controllerView.typeControllerView){
//				this.chart.controllerView.typeControllerView.changeType(this.chart, "mountain");
//			}			
//		}
	}

	if(this.chart != null) {
		if(this.index == 0) {
			// this.scrollMapping.setStatus(this.mapping.status);

			if (dataReady && this.chart.mainTechnical.data != null) {
				this.chart.analysis();
				var technicalList = this.technicalList;

				if (this.chart.interval != null) {
					clearInterval(this.chart.interval);
				}
//				if (this.chart.period == "1m" || this.chart.period == "5m" || this.chart.period == "tick") {
				if (this.chart.period == "1m" || this.chart.period == "5m") {
					this.chart.interval = setInterval(function() {

						for (var i = 0; i < technicalList.length; i++) {
							var tech = technicalList[i];
							if (tech.dataLoadType == SvgChartTechnical.DATA_LOAD_TYPE_URL) {
								tech.dataStatus = SvgChartTechnical.DATA_STATUS_LOADING;
								tech.loadData();
							}
						}

					}, 60000);
				}				

				// var lastCloseTechnical = this.chart.lastCloseTechnical;
//
			// if (lastCloseTechnical != null) {
				// if (this.chart.interval != null) {
					// //console.log("clearInterval:" + this.chart.interval);
					// clearInterval(this.chart.interval);
				// }
				// this.chart.interval = setInterval(function() {
					// mainTechnical.dataStatus = SvgChartTechnical.DATA_STATUS_LOADING;
				  // mainTechnical.loadData();
					// // lastCloseTechnical.dataStatus = SvgChartTechnical.DATA_STATUS_LOADING;
				  // // lastCloseTechnical.loadData();
				// }, 60000);
				// //console.warn("this.chart.interval:" + this.chart.interval);
			// }
			}
			// this.chart.draw();
		// }
		// else {
			// this.draw();
		}
	}

	this.chart.onStatusChange();

	// if (dataReady && this.chart.mainTechnical.data != null) {
		// maskLayer("hide");
		// if ($) $.mobile.loading("hide");
	// }
};

SvgChartView.prototype.updateCanvasSize = function()
{
	var width = this.panelDiv.clientWidth;
	var height = this.panelDiv.clientHeight;

//	this.labelDiv.style.width = width - 110 + "px";
	// console.log("this.panelDiv.clientWidth;");
	// console.log(width);
	// console.log(this.mapping.drawAreaWidth);

	if(width != this.mapping.drawAreaWidth) {
		if(window.devicePixelRatio == 2) {
			// this.panelDrawCanvas.style.width = width + "px";
			// this.panelDrawCanvas.width = width * 2;
//
			// this.panelDrawCanvas.style.height = height + "px";
			// this.panelDrawCanvas.height = height * 2;
//
			// var context = this.panelDrawCanvas.getContext("2d");
			// context.scale(2, 2);
		}
		else {
			// this.panelDrawCanvas.width = width;
		}

		// this.panelActionCanvas.width = width;

		// if(this.scrollDrawCanvas != null) {
			// this.scrollDrawCanvas.width = width;
		// }
//
		// if(this.scrollActionCanvas != null) {
			// if(window.devicePixelRatio == 2) {
				// this.scrollActionCanvas.style.width = width + "px";
				// this.scrollActionCanvas.width = width * 2;
//
				// var heightScroll = this.scrollDiv.clientHeight;
//
				// this.scrollActionCanvas.style.height = heightScroll + "px";
				// this.scrollActionCanvas.height = heightScroll * 2;
//
				// context = this.scrollActionCanvas.getContext("2d");
				// context.scale(2, 2);
			// }
			// else {
				// this.scrollActionCanvas.width = width;
			// }
		// }

		this.mapping.setDrawAreaSize(width, this.mapping.drawAreaHeight);

		if(this.index == 0 && this.chart.showScroll) {
			this.chart.scrollView.scrollMapping.setDrawAreaSize(width, SvgChartGlobal.LAYOUT_SCROLL_HEIGHT);
		}
	}
};

SvgChartView.prototype.draw = function()
{
	if (this.chart.theme == "black") {
		this.mapping.panel.gridColor = "#262F3D";
	} else {
		this.mapping.panel.gridColor = "#DCDCDC";
	}
	// console.log("========ChartView draw start===========");
	// Update width
	this.updateCanvasSize();

	if(this.mapping.updated == true) {
		this.drawPanel();
	}

	this.mapping.updated = false;
};

SvgChartView.prototype.drawPanel = function()
{
	// console.log("========ChartView drawPanel start===========");
	var context = this.panelDrawCanvas;

	SvgChartDrawUtil.clear(this.panelDrawCanvas);
	if (this.index == 0) {
		SvgChartDrawUtil.clear(this.chart.newsCanvas);
	}

	// Project technicals
	if(this.mapping.status == SvgChartTechnical.DATA_STATUS_OK && this.chart.mainMapping.status == SvgChartTechnical.DATA_STATUS_OK) {
		var util = new SvgChartUtil();
		var projector = new SvgChartProjector();

		// Calculate vertical axis
		projector.updateDataMinMax(this.technicalList, this.mapping);
		// console.debug("DataMinMax : ", this.mapping.dataMinValue, this.mapping.dataMaxValue);
		// console.debug("DataMinMax : ", this.mapping.dataMinValue1, this.mapping.dataMaxValue1);

//		var points = util.getOptimizedPoints(this.mapping.dataMinValue, this.mapping.dataMaxValue, this.vAxisParts, this.vDecimals);
//		var points1 = util.getOptimizedPoints(this.mapping.dataMinValue1, this.mapping.dataMaxValue1, this.vAxisParts, this.vDecimals);

		var points = [];
		var vAxisIndex = -1;
		var vAxisIndex1 = -1;
		for (var i = 0; i < 10; i++) {
			if (this.mapping.dataMinValue[i] == null) continue;
			points[i] = util.getOptimizedPoints(this.mapping.dataMinValue[i], this.mapping.dataMaxValue[i], this.vAxisParts, this.vDecimals);
			this.mapping.dataMinValue[i] = points[i][0];
			this.mapping.dataMaxValue[i] = points[i][points[i].length - 1];
			
			if (vAxisIndex < 0) {
				vAxisIndex = i;
			} else if (vAxisIndex1 < 0) {
				vAxisIndex1 = i;
			}
		}
		if (vAxisIndex < 0) {
			vAxisIndex = 0;
			points = [0, 0.1];
		}

		var vAxisValues = new Array();
		var vAxisValues1 = new Array();

//		var type = "price";
//		if (this.technicalList[0].name == "volume") {
//			type = "volume";
//		}
		var type = "volume";

		for(var i = 0; i < points[vAxisIndex].length; i++) {
			var label = MST.SvgChart.numberFormatFunc(points[0][i], type);
			if (vAxisIndex == 0 && this.mapping.benchmark == true) {
				label = label + "%";
			}
			vAxisValues.push({value:points[vAxisIndex][i], label:label});
			
			if (vAxisIndex1 < 0) continue;
			var label1 = MST.SvgChart.numberFormatFunc(points[vAxisIndex1][i], type);
			vAxisValues1.push({value:points[vAxisIndex1][i], label:label1});
		}

		this.mapping.setVAxisValues(vAxisValues, vAxisValues1);
		this.mapping.vAxisIndex = vAxisIndex;

		// Calculate horizental axis
		if(this.index == 0) {

			var hAxisValues;

			var name = this.chart.mainTechnical.name;
			var period = this.chart.period;

			if (name == "intraday") {

				var exchangeCode = this.chart.exchange;
				// hAxisValues = SvgChartUtil.exchangeHAxis(SvgChartGlobal.exchange[exchangeCode], period.substring(0,1));
				hAxisValues = SvgChartUtil.exchangeHAxis(SvgChartGlobal.exchange[exchangeCode], this.chart.detailTimeFormat, this.chart.mainTechnical.data[0].date, this.chart.mainTechnical.lastCloseStartIndex);

			} else {

				hAxisValues = new Array();

				var label = this.chart.timeFormat(new Date());
				var labelWidth = SvgChartUtil.measureText(this.chart, label);
				var labelGap = Math.ceil((labelWidth + 20) / this.mapping.getUnitWidth());
				var rightGap = Math.max(Math.ceil((labelWidth / 2 - this.mapping.paddingRight) / this.mapping.getUnitWidth()), 0);
				var leftGap = Math.max(Math.ceil((labelWidth / 2 - this.mapping.paddingLeft) / this.mapping.getUnitWidth()), 0);

				var lastIndex = this.chart.mainTechnical.data.length - 1;
				var prevIndex = lastIndex + labelGap;

				var timeFormat = this.chart.timeFormat;

				for(var i = this.mapping.dataStartIndex + this.mapping.dataLength - 1 - rightGap; i >= this.mapping.dataStartIndex + leftGap - 1 && i >= 0; i--) {
					var label = timeFormat(this.chart.mainTechnical.data[i].date);
					var labelPrev = i > 0 ? timeFormat(this.chart.mainTechnical.data[i - 1].date) : "";

					if (this.chart.period == "1m") {
						var m = label.substring(label.length - 2);
						if (!(m == "00" || (m == "30" && labelGap < 30))) continue;
					 } else if (this.chart.period == "5m") {
						 var m = label.substring(label.length - 2);
						 if (m != "00") continue;
					// } else if (this.chart.period == "1h") {
						// var m = label.substring(label.length - 2);
						// if (m != "00") continue;
					// } else {
						// // if (label != labelPrev && prevIndex - i >= labelGap) {
							// // hAxisValues.push({value:i, label:label});
							// // prevIndex = i;
						// // }
					}
					if(label != labelPrev && prevIndex - i >= labelGap) {
						hAxisValues.push({value:i, label:label});
						prevIndex = i;
					}
				}
			}

			this.mapping.setHAxisValues(hAxisValues);

//			this.mapping.chartTitle = this.title1 + this.title2;
            this.mapping.chartTitle = this.title1;
		}
		else {
			this.mapping.setHAxisValues(this.chart.mainMapping.hAxisValues);
			this.mapping.chartTitle = "";
		}

		// Project axis
		if (this.chart.chartConfig.globalViewConfig.axis) {
			projector.projectAxis(this.chart, context, this.mapping, this.index);
		}

		// Project technicals
		projector.project(context, this.technicalList, this.mapping);

		if (this.chart.chartConfig.globalViewConfig.rightAxis) {
			projector.projectAxisText(this.chart, context, this.mapping, this.index);
		}

		this.drawSketch();
	}
};

SvgChartView.prototype.onClickEvent = function(target, param)
{
	var benchmarks = this.chart.benchmarks;
	var find = false;

	if (benchmarks != null && benchmarks.length > 0) {
		for (var i = 0; i < benchmarks.length; i++) {
			if (benchmarks[i] == param) {
				benchmarks.splice(i, 1);
				find = true;
				break;
			}
		}
		if (find) {
			this.chart.setBenchmark(benchmarks);
			if (this.chart.removeBenchmarkFunction != null) {
				this.chart.removeBenchmarkFunction(param);
			}
		}
	}

	if (!find) {
		this.chart.removeTechnical(param);
		if (this.chart.removeIndicatorFunction != null) {
			this.chart.removeIndicatorFunction(param);
		}
	}
};

SvgChartView.prototype.onTouchEvent = function(pharse, cursorCoords)
{
//	console.log(this.index + "," + pharse);

	var index = this.mapping.unTransformIndex(cursorCoords.x);
	var value = this.mapping.unTransformValue(cursorCoords.y);
	if (this.mapping.benchmark == true && this.mapping.startValue != null) {
		value = (value / 100 + 1) * this.mapping.startValue;
	}

    var y = cursorCoords.y;
	if (pharse == 'touchstart') {
        this.moveStatus = {y: y};
    } else if (pharse == 'touchmove' && this.moveStatus != null) {
        this.top += y - this.moveStatus.y;
        this.frameDiv.style.top = this.top + "px";
        this.moveStatus.y = y;
    }
    return;

    if (this.chart.sketchMode) {
		if (pharse == 'touchstart') {
			if (this.chart.sketch.isNew()) {
				this.sketch = this.chart.sketch;
				this.sketch.period = this.chart.period;
				this.sketch.addPoint(index, value, false);
			} else if (this.chart.sketch == this.sketch) {
				this.sketch.addPoint(index, value, false);
			}
			
			if (this.sketch != null && this.sketch.isFinish()) {
				this.sketchList.push(this.sketch);
//				this.chart.sketch = null;
				this.chart.sketchMode = false;
				if (this.chart.afterSketch != null) {
					this.chart.afterSketch();
				}
			}
		} else if (pharse == 'touchmove') {
			if (this.chart.sketch == this.sketch) {
				this.sketch.addPoint(index, value, true);
			}
		}
		
		this.drawSketch();

	} else {
		if (pharse == 'touchstart') {
			this.sketch = null;
			for (var i = 0; i < this.sketchList.length; i++) {
				var sketch = this.sketchList[i];
				
				var selectedPoint = sketch.pointSelected(this.mapping, cursorCoords.x, cursorCoords.y);
				if (selectedPoint >= 0) {
					this.chart.sketch = sketch;
					this.sketch = sketch;
					this.chart.sketch.selectedPoint = selectedPoint;
					
					sketch.moveStatus = {index: index, value: value};
					break;
				}
				
				if (sketch.selected != null && sketch.selected(this.mapping, cursorCoords.x, cursorCoords.y)) {
					this.chart.sketch = sketch;
					this.sketch = sketch;
					
					sketch.moveStatus = {index: index, value: value};
					break;
				}
			}
			
			this.drawSketch();
		} else if (pharse == 'touchmove') {
			if (this.chart.sketch != null && this.chart.sketch.moveStatus != null) {
				var sketch = this.chart.sketch;
				sketch.move(index, value);
				
				this.drawSketch();
			}
		}
	}

	
	return true;
};

SvgChartView.prototype.drawSketch = function() {
	SvgChartDrawUtil.clear(this.sketchCanvas);
	
	for (var i = 0; i < this.sketchList.length; i++) {
		var sketch = this.sketchList[i];
		if (sketch.period != this.chart.period) continue;
		
		sketch.project(this.sketchCanvas, this.mapping);
		if (this.sketch == sketch) {
			sketch.projectPoint(this.sketchCanvas, this.mapping);
		}
	}
	
	if (this.chart.sketchMode) {
		if (this.sketch != null && this.sketch.period == this.chart.period) {
			this.sketch.project(this.sketchCanvas, this.mapping);
			this.sketch.projectPoint(this.sketchCanvas, this.mapping);
		}
	}
}

MST.SvgChartColorSelect = SvgChartColorSelect;
MST.SvgChartTechnicalSetting = SvgChartTechnicalSetting;
MST.SvgChartView = SvgChartView;
}());